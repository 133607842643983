
import { defineComponent, reactive, PropType, ref, watch, nextTick } from 'vue';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import { getWithdrawInfo } from '@/API/rv/rvDealer';

import WithdrawCompletedInfo from '@/views/RetailValidation/Dealer/WithdrawCompletedInfo.vue';
import { message } from 'ant-design-vue';

type Key = ColumnProps['key'];

const columnsAll = [
    {
        title: '项目编号',
        dataIndex: 'programCode',
        key: 'programCode',
        width: 150,
        //ellipsis: true
    },
    {
        title: '项目名称',
        dataIndex: 'programNameCn',
        key: 'programNameCn',
        width: 150,
    },
    {
        title: '项目类型',
        dataIndex: 'offerTypeNameCn',
        key: 'offerTypeNameCn',
        width: 150,
    },
    {
        title: '申请状态',
        dataIndex: 'status',
        key: 'status',
        width: 150,
        slots: { customRender: 'applyStatus' },
    },
];

export default defineComponent({
    name: 'SelectProgramConfirm',
    components: {
        WithdrawCompletedInfo,
    },
    props: {
        withdrawModelVisible: {
            type: Boolean,
            default: false,
            required: true,
        },
        recordId: {
            type: Number,
            default: -1,
            required: true,
        },
        vinNo: {
            type: String,
            default: '',
            required: true,
        },
        retailDate: {
            type: String,
            default: '',
            required: true,
        },
        brand: {
            type: String,
            default: '',
            required: true,
        },
    },
    emits: ['update:withdrawModelVisible'],
    setup(props, { emit }) {
        const status = ref<string>('');
        // const visibleModal = ref((props as any).withdrawModelVisible);
        const columns = ref<any[]>([]);
        const dataSource = ref([]);
        const isCheckedFlg = ref<boolean>(false);
        const isShowNoFlg = ref<boolean>(false);
        const rowSelection = reactive<{
            selectedRowKeys: Key[];
            selectedRowData: any[];
            onChange: Function;
            fixed: string;
        }>({
            onChange: (selectedRowKeys: Key[], selectedRowData: any[]) => {
                rowSelection.selectedRowKeys = selectedRowKeys;
                rowSelection.selectedRowData = selectedRowData;
            },
            selectedRowData: [],
            selectedRowKeys: [],
            fixed: 'left',
        });
        const withdrawCompletedVisible = ref<boolean>(false);
        const noWithdrawData = ref(false);
        const handlerConfirm = (status: string): void => {
            switch (status) {
                case '0':
                    if (isCheckedFlg.value) {
                        //申报项目状态有”核查中“或者”已付款“
                        isShowNoFlg.value = true;
                        noWithdrawData.value = true;
                    } else {
                        //申报项目状态全部=已提交,点击下一步按钮弹窗变为“车辆撤回零售完成率影响情况”
                        // visibleModal.value = false;
                        emit('update:withdrawModelVisible', false);
                        withdrawCompletedVisible.value = true;
                    }
                    break;
                case '1':
                    emit('update:withdrawModelVisible', false);
                    withdrawCompletedVisible.value = true;
                    break;
            }
        };

        const getInfo = () => {
            getWithdrawInfo(props.recordId).then((res: any) => {
                if (res.code === '0' && res.data.length === 0) {
                    noWithdrawData.value = true;
                    status.value = '1';
                    dataSource.value = res.data;
                } else if (res.code === '0' && res.data.length > 0) {
                    dataSource.value = res.data;
                    status.value = '0';
                    const ls = ['已提交', '已撤回', '延期撤回', '已终止'];
                    isCheckedFlg.value = (dataSource.value as any).some(
                        (item: any) => {
                            return !ls.includes(item.status);
                        }
                    );
                } else {
                    message.error(res.message);
                }
            });
        };

        watch(
            () => props.withdrawModelVisible,
            (newVal) => {
                if (newVal) {
                    getInfo();
                    isShowNoFlg.value = false;
                    noWithdrawData.value = false;
                }
            },
            { immediate: true }
        );

        return {
            columns,
            columnsAll,
            rowSelection,
            handlerConfirm,
            dataSource,
            isCheckedFlg,
            isShowNoFlg,
            status,
            // visibleModal,
            withdrawCompletedVisible,
            noWithdrawData,
        };
    },
});
